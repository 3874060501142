// By default, Klaro will load the config from  a global "klaroConfig" variable.
// You can change this by specifying the "data-config" attribute on your
// script take, e.g. like this:
// <script src="klaro.js" data-config="myConfigVariableName" />
// You can also disable auto-loading of the consent notice by adding
// data-no-auto-load=true to the script tag.
window['klaroConfig'] = {
    // You can customize the ID of the DIV element that Klaro will create
    // when starting up. If undefined, Klaro will use 'klaro'.
    elementID: 'klaro',

    // How Klaro should store the user's preferences. It can be either 'cookie'
    // (the default) or 'localStorage'.
    storageMethod: 'cookie',

    // You can customize the name of the cookie that Klaro uses for storing
    // user consent decisions. If undefined, Klaro will use 'klaro'.
    cookieName: 'klaro',

    // You can group apps by their purpose in the modal. This is advisable
    // if you have a large number of apps. Users can then enable or disable
    // entire groups of apps instead of having to enable or disable every app.
    groupByPurpose: false,

    /*
    If set to `true`, Klaro will render the texts given in the
    `consentModal.description` and `consentNotice.description` translations as HTML.
    This enables you to e.g. add custom links or interactive content.
    */
    htmlTexts: false,

    // You can also set a custom expiration time for the Klaro cookie.
    // By default, it will expire after 120 days.
    cookieExpiresAfterDays: 395,

    // You can change to cookie domain for the consent manager itself.
    // Use this if you want to get consent once for multiple matching domains.
    // If undefined, Klaro will use the current domain.
    //cookieDomain: '.github.com',

    // Put a link to your privacy policy here (relative or absolute).
    privacyPolicy: '/politique-de-confidentialite',

    // Defines the default state for applications (true=enabled by default).
    default: false,

    // If "mustConsent" is set to true, Klaro will directly display the consent
    // manager modal and not allow the user to close it before having actively
    // consented or declines the use of third-party apps.
    mustConsent: false,

    // Show "accept all" to accept all apps instead of "ok" that only accepts
    // required and "default: true" apps
    acceptAll: true,

    // replace "decline" with cookie manager modal
    hideDeclineAll: false,

    // You can define the UI language directly here. If undefined, Klaro will
    // use the value given in the global "lang" variable. If that does
    // not exist, it will use the value given in the "lang" attribute of your
    // HTML tag. If that also doesn't exist, it will use 'en'.
    lang: 'fr',

    // You can overwrite existing translations and add translations for your
    // app descriptions and purposes. See `src/translations/` for a full
    // list of translations that can be overwritten:
    // https://github.com/KIProtect/klaro/tree/master/src/translations

    // Example config that shows how to overwrite translations:
    // https://github.com/KIProtect/klaro/blob/master/src/configs/i18n.js
    translations: {
        fr: {
            acceptAll: 'Tout accepter',
            acceptSelected: 'Valider la sélection',
            consentNotice: {
                description: "Ce site utilise des cookies dits « techniques » nécessaires à son bon fonctionnement, des cookies de mesure d’audience (génération des statistiques de fréquentation et d’utilisation du site afin d’analyser la navigation et d’améliorer le site), des cookies de modules sociaux (en vue du partage de l’URL d’une page du site sur les réseaux sociaux), des cookies publicitaires (partagés avec des partenaires) et des cookies Youtube. Vous pouvez tous les accepter, les refuser ou choisir votre configuration en cliquant sur les boutons correspondants. Pour en savoir plus, consulter notre {privacyPolicy}.",
                learnMore: "Paramétrer les cookies"
            },
            app: {
                disableAll: {
                    description: "Utilisez ce bouton pour activer ou désactiver tous les services.",
                    title: "Activer ou désactiver tous les services"
                }
            },
            consentModal: {
                description: "Ici, vous pouvez voir et paramétrer les cookies qui peuvent être déposés sur votre navigateur lors de votre consultation du site.",
                privacyPolicy: {
                    text: 'Pour en savoir plus, merci de consulter notre {privacyPolicy}.',
                    name: 'politique de confidentialité',
                },
                title: "Paramétrer les cookies"
            },
            decline: 'Tout refuser',
            'google-analytics': {
                description: 'Ce service permet l’analyse de l’utilisation et des performances de notre site afin de nous fournir des mesures d’audience de nos contenus.'
            },
            'gtm': {
                description: 'Ici se trouve ma description'
            },
            'lazy-stats': {
                description: "Les cookies statistiques permettent de mesurer l’utilisation et les performances du Site et d’en améliorer le fonctionnement.",
            },
            'youtube': {
                description: 'Ce service de partage de vidéo permet d\'enrichir le site de contenus multimédia et augmente sa visibilité.'
            },
            'sharethis': {
                description: 'Ce service permet le bon fonctionnement des boutons de partage de nos contenus sur les réseaux sociaux Facebook, Twitter et par email.'
            },
            'facebook-pixel': {
                description: 'Le pixel Facebook est un extrait de code ajouté au site web et qui permet de mesurer l’efficacité des publicités en suivant les actions entreprises par les internautes sur le site web.'
            },
            'twitter': {
                description: 'Ce cookie permet le bon fonctionnement des boutons de partage de nos contenus sur le réseau social Twitter.'
            },
            'facebook': {
                description: 'Ce cookie permet le bon fonctionnement des boutons de partage de nos contenus sur le réseau social Facebook.'
            },
            purposes: {
                statistics: 'Statistiques',
                media: 'Media',
                social: 'Réseaux sociaux',
                analytics: 'Analytics'
            },
        },
    },

    // This is a list of third-party apps that Klaro will manage for you.
    apps: [
   // The apps will appear in the modal in the same order as defined here.
        {
            name: 'sharethis',
            default: false,
            title: 'ShareThis',
            purposes: ['social'],
            required: false,
            optOut: false,
            callback: function (consent) {
                if (consent) {
                    document.querySelectorAll(".SocialNetworks")
                        .forEach(function (el) {
                            el.classList.remove("klaro-hidden");
                        });
                } else {
                    document.querySelectorAll(".SocialNetworks")
                        .forEach(function (el) {
                            el.classList.add("klaro-hidden");
                        });
                }
            },
        },
        {
            name: 'youtube',
            default: false,
            title: 'Youtube',
            purposes: ['media'],
            cookies: [['.youtube.com']],
            required: false,
            optOut: false,
            onlyOnce: false,
            callback: function (consent) {
                if (consent) {
                    let iframe = document.querySelectorAll("iframe.youtube");
                    iframe.forEach(function (el) {
                        el.classList.remove("klaro-hidden");
                        el.closest(".video-embed").classList.remove("klaro-hidden-embed");
                    });

                    let fallback = document.querySelectorAll(".youtube-fallback");
                    fallback.forEach(function (el) {
                        el.classList.add("klaro-hidden");
                    });
                } else {
                    let iframe = document.querySelectorAll("iframe.youtube");
                    iframe.forEach(function (el) {
                        el.classList.add("klaro-hidden");
                        el.closest(".video-embed").classList.add("klaro-hidden-embed");
                    });

                    let fallback = document.querySelectorAll(".youtube-fallback");
                    fallback.forEach(function (el) {
                        el.classList.remove("klaro-hidden");
                    });
                }
            },
        },
        {
            name: 'twitter',
            default: false,
            title: 'Twitter',
            purposes: ['social'],
            cookies: [['.twitter.com']],
            required: false,
            optOut: false,
            onlyOnce: false,
        }, {
            name: 'facebook',
            default: false,
            title: 'Facebook',
            purposes: ['social'],
            cookies: [['.facebook.com']],
            required: false,
            optOut: false,
            onlyOnce: false,
        }

    ],
};
